.date-range-wrapper {
   flex-direction: column;
   position: relative;
   width: 100%;
   .date-range-picker {
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      padding: 0px;
      position: absolute;
      margin-top: 5px;
   }
   .date-range-picker .rdrCalendarWrapper {
      font-size: 14px;
      transform: scale(0.9);
   }
   .date-range-picker .rdrMonthAndYearWrapper {
      padding: 0px;
   }
   .date-range-picker .rdrMonth {
      width: 250px;
      padding-bottom: 0px;
   }
   .date-range-picker .rdrMonthName {
      padding: 0px;
      padding-left: 5px;
   }
   .date-range-picker .rdrNextPrevButton,
   .date-range-picker .rdrNextButton {
      background: #ffffff;
   }
   .date-range-picker .rdrDay {
      height: 30px;
   }
   .date-range-picker .rdrDayToday {
      padding: 0px;
      outline: none;
   }
   .date-range-picker .rdrDayNumber {
   }
   .rdrDay .rdrDayHovered {
      background-color: rgb(250, 250, 249) !important;
   }

   .date-range-picker .rdrDayNumber::after {
      display: none !important;
      content: none !important;
   }
}

.input-container {
   position: relative;
   width: 100%;
   input::placeholder {
      color: black;
   }

   .input-field {
      width: 100%;
      padding: 14px 10px;
      border: none;
      border-radius: 10px;
      background: #eeeeee;
      color: #000;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      &:hover {
      }
   }

   .input-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      cursor: pointer;
   }
}
