.production-schedule {
   display: flex;
   gap: 2px;
   .production-day:not(:last-child) {
      border-right: 1px solid #cacaca;
   }
}

.production-day {
   text-align: center;
   min-width: 450px;
   &-date {
      background: #000000;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
      padding: 7px;
   }
   &-override {
      background: #efefef;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      padding-block: 2px;
      min-height: 25px;
   }
   &-slot-type {
      font-weight: 500;
      font-size: 14px;
      color: #1374d5;
      padding-block: 4px;
      text-align: center;

      &-premium,
      &-regular {
         padding: 0 !important;
      }
      &-premium {
         border-right: 1px solid #cacaca;
      }
   }
   &-row {
      margin: 0 !important;
      width: inherit;

      &-column {
         border-bottom: 1px solid #cacaca;
         width: 100%;
         padding-inline: 5px !important;
         display: flex;
         justify-content: center;
         width: inherit;
      }
   }
}
.slot-card {
   width: 100px !important;
   height: 100px;
   padding: 6px 7px;
   margin-block: 10px;
   border-radius: 10px;
   text-align: left;
   display: flex;
   flex-direction: column;
   gap: 3px;
   border: 1px solid #cacaca;
   box-shadow: 0px 0px 10px 0px #00000033;

   p {
      margin: 0;
   }

   img {
      cursor: pointer;
   }
   &-order {
      font-weight: 600;
      font-size: 13px;
      color: #495057;
      font-family: Poppins;
      line-height: 18px;
   }

   &-client,
   &-trailer {
      font-weight: 400;
      font-size: 13px;
      color: #495057;
      line-height: 18px;
   }

   &-selected {
      border: 1px solid #0b4d89;
      box-shadow: 0px 2px 10px 0px #297eb6;
   }
}

.stages {
   max-width: 137px;
   p {
      margin: 0;
   }
   &-heading {
      padding: 37px 43px;
      text-align: center;
      background-color: #000000;
      color: #ffffff;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0px;
      text-transform: capitalize;
   }
   &-stage {
      text-align: center;
      &-stagename {
         background: linear-gradient(-45deg, #2c3e50 0%, #2980b9 80%);

         font-weight: 500;
         font-size: 12px;
         color: #ffffff;
         padding: 8px;
      }
      &-station {
         background: #1374d552;
         font-weight: 500;
         font-size: 14px;
         color: #000000;
         //  padding: 33px 10px;
         height: 87px;
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }
}

.production-button {
   border: 1px solid #1374d5 !important;
   padding: 8px 25px !important;
   p {
      margin: 0;
      color: #1374d5;
   }
}
