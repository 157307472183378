.text-trim {
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
}

.trim-text {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
}
.trim-text-2 {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}

.bg-grad-primary {
   background: linear-gradient(
      144deg,
      #f65d4f 0%,
      #b14ea6 33.33%,
      #7a65d5 66.66%,
      #3bafe5 100%
   );
}

.pointer {
   cursor: pointer;
}

.objfit-cover {
   object-fit: cover;
}

.sep-1px {
   height: 1px;
   display: block;
   margin-block: em(37.5);
   background-color: $bdr-clr-4;
}

.resize-none {
   resize: none;
}
.text-webkit-center {
   text-align: -webkit-center;
   text-align: -moz-center;
}

.transform-none {
   transform: none !important;
}

.bdr-0 {
   border-color: transparent !important;
}

.minw-100 {
   min-width: 100% !important;
}

.thumbnail-rounded {
   max-width: em(150);
   height: em(150);
   width: 100%;
   object-fit: cover;
   @include radius(50%);
}

.word-break {
   word-break: break-word;
}

.gtw-full {
   width: 100%;
}
.gt-content-wrapper {
   padding: calc(#{em(70)} + 24px) calc(24px / 2) 60px calc(24px / 2);
}

.scrolling-container {
   overflow-y: auto;
   max-height: 59vh;
   overflow-x: hidden;
   &::-webkit-scrollbar {
      width: 5px;
   }
   &::-webkit-scrollbar-thumb {
      @include radius(5px);
      background-color: rgba($color: $red, $alpha: 0.5);
   }
}

.gt-card-head-bgimg {
   background: url("../../images/bg-auth-card.png") no-repeat center/cover;
}

.gt-filter-icon {
   height: em(31);
   width: em(27);
}

.gt-line-break-two {
   word-break: break-word;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}

.table-clickable-column {
   cursor: pointer;
   color: #2090ff;
   text-decoration: underline;
}

.text-right {
   text-align: right;
}

.text-left {
   text-align: left;
}
